import _compression from "compression";
import _send from "utilise/send";
import _key from "utilise/key";
import _path from "path";
import _express from "express";
import _log from "utilise/log";
import _lo from "utilise/lo";
var exports = {};

// -------------------------------------------
// Serves the client library /ripple.js
// -------------------------------------------
exports = function serve(ripple, {
  server,
  serve,
  client = "ripple"
} = {}) {
  log("creating");
  const {
    http = server
  } = ripple.server || {};
  if (!http) return ripple;
  const app = expressify(http),
        path = local(serve, client),
        compress = compression();
  app.use(`/${client}.js`, compress, send(path(prod ? "min.js" : "bundle.js")));
  app.use(`/${client}.bundle.js`, compress, send(path("bundle.js")));
  app.use(`/${client}.min.js`, compress, send(path("min.js")));
  return ripple;
};

const expressify = server => server.express || key("_events.request")(server) || server.on("request", express())._events.request;

const local = (path, client) => ext => resolve(path, `./${client}.${ext}`);

const compression = _compression,
      send = _send,
      key = _key,
      {
  resolve
} = _path,
      express = _express,
      log = _log("[ri/serve]"),
      lo = _lo,
      prod = lo("production") == "production";

export default exports;